var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "subTabNone": _vm.$route.params._campaign,
      "sh": "캠페인",
      "tabActive": "캠페인",
      "bg": "/images/sub/visual/sv4.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "infant-view infant-view--mobile",
    attrs: {
      "src": "/images/sub/campaign/infant/mobile.jpg"
    }
  }, [_c('div', {
    staticClass: "infant-view__video"
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/G0iuN10o3QE?si=Cnz_YbHkvPbuFFIK",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  })]), _c('a', {
    staticClass: "infant-view__button infant-view__button--1",
    attrs: {
      "href": _vm.buttonlink,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))]), _c('a', {
    staticClass: "infant-view__button infant-view__button--2",
    attrs: {
      "href": _vm.buttonlink,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])]), _c('v-img', {
    staticClass: "infant-view infant-view--pc",
    attrs: {
      "src": "/images/sub/campaign/infant/image.jpg"
    }
  }, [_c('div', {
    staticClass: "infant-view__video"
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/G0iuN10o3QE?si=Cnz_YbHkvPbuFFIK",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  })]), _c('a', {
    staticClass: "infant-view__button infant-view__button--1",
    attrs: {
      "href": _vm.buttonlink,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))]), _c('a', {
    staticClass: "infant-view__button infant-view__button--2",
    attrs: {
      "href": _vm.buttonlink,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])]), _c('div', {
    staticClass: "mt-40 mt-lg-60"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-9"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("목록보기")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }