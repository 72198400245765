<template>
    <client-page>
        <!-- <sub-visual :subTabNone="$route.params._campaign" sh="캠페인" tabActive="캠페인" bg="/images/sub/visual/sv4.jpg"> </sub-visual> -->
        <sub-visual sh="캠페인" tabActive="캠페인" bg="/images/sub/visual/sv4.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <v-tabs v-model="tab" hide-slider height="auto" class="v-tabs--primary tab--block">
                    <v-tab>국내 캠페인</v-tab>
                    <v-tab>국제 캠페인</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <v-card flat tile outlined to="/campaign/campaign/infant">
                                    <v-img src="/images/sub/campaign/infant/thumb.png" />
                                    <v-card-title class="font-size-16 font-size-md-18">위기영아지원 캠페인</v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item></v-tab-item>
                </v-tabs-items>

                <!-- <campaign-list v-if="!$route.params._campaign && $route.query.mode !== 'input'" /> -->
                <!-- <campaign-write v-else-if="$route.query.mode === 'input'"/>
                <campaign-view v-else/>  -->
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CampaignView from "@/components/client/support/campaign-view.vue";
import CampaignList from "@/components/client/support/campaign-list.vue";
import CampaignWrite from "@/components/client/support/campaign-write.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        CampaignView,
        CampaignList,
        CampaignWrite,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
    watch: {
        query() {},
    },
};
</script>

<style>
</style>