<template>
    <client-page>
        <sub-visual :subTabNone="$route.params._campaign" sh="캠페인" tabActive="캠페인" bg="/images/sub/visual/sv4.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <!-- Mobile -->
                <v-img src="/images/sub/campaign/infant/mobile.jpg" class="infant-view infant-view--mobile">
                    <div class="infant-view__video">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/G0iuN10o3QE?si=Cnz_YbHkvPbuFFIK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                    </div>
                    <a :href="buttonlink" target="_blank" class="infant-view__button infant-view__button--1">{{ buttonText }}</a>
                    <a :href="buttonlink" target="_blank" class="infant-view__button infant-view__button--2">{{ buttonText }}</a>
                </v-img>
                <!--// Mobile -->

                <!-- PC -->
                <v-img src="/images/sub/campaign/infant/image.jpg" class="infant-view infant-view--pc">
                    <div class="infant-view__video">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/G0iuN10o3QE?si=Cnz_YbHkvPbuFFIK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                    </div>
                    <a :href="buttonlink" target="_blank" class="infant-view__button infant-view__button--1">{{ buttonText }}</a>
                    <a :href="buttonlink" target="_blank" class="infant-view__button infant-view__button--2">{{ buttonText }}</a>
                </v-img>
                <!--// PC -->
                <div class="mt-40 mt-lg-60">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn @click="$router.go(-1)" outlined color="grey-9">목록보기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
    },
    data: () => ({
        buttonlink: "https://secure.donus.org/withcan/pay/step1?_gl=1*180luv6*_ga*MTY5ODQ1OTg0Mi4xNzE3NDY4Mzcy*_ga_1KFQVNYJMP*MTczNjc1MjExNi4xMzAuMC4xNzM2NzUyMTE2LjYwLjAuMA",
        buttonText: "아이들 후원하기",
    }),
};
</script>

<style lang="scss" scoped>
.infant-view {
    --image-width: 698;
    --image-height: 14999;
    position: relative;
    &--pc {
        display: none;
    }
    &__video,
    &__button {
        position: absolute;
        display: block;
    }
    &__video {
        --video-x: 29;
        --video-y: 1505;
        --video-width: 640;
        --video-height: 354;
        left: calc(var(--video-x) / var(--image-width) * 100%);
        top: calc(var(--video-y) / var(--image-height) * 100%);
        height: calc(var(--video-height) / var(--image-height) * 100%);
        width: calc(var(--video-width) / var(--image-width) * 100%);
        border-radius: 10px;
        overflow: hidden;
    }
    &__button {
        --button-width: 311;
        --button-height: 93;
        width: calc(var(--button-width) / var(--image-width) * 100%);
        height: calc(var(--button-height) / var(--image-height) * 100%);
        border-radius: 999px;
        text-align: center;
        font-size: 1rem;
        opacity: 0;
        &--1 {
            --button-1-x: 193;
            --button-1-y: 1465;
            left: calc(var(--button-1-x) / var(--image-width) * 100%);
            bottom: calc(var(--button-1-y) / var(--image-height) * 100%);
        }
        &--2 {
            --button-2-x: 193;
            --button-2-y: 132;
            left: calc(var(--button-2-x) / var(--image-width) * 100%);
            bottom: calc(var(--button-2-y) / var(--image-height) * 100%);
        }
    }
}

@media (min-width: 723px) {
    .infant-view {
        --image-width: 1200;
        --image-height: 6473;
        &--mobile {
            display: none;
        }
        &--pc {
            display: block;
        }
        &__video {
            --video-x: 209;
            --video-y: 683;
            --video-width: 783;
            --video-height: 434;
            border-radius: 16px;
        }
        &__button {
            --button-width: 160;
            --button-height: 48;
            &--1 {
                --button-1-x: 527;
                --button-1-y: 617;
            }
            &--2 {
                --button-2-x: 128;
                --button-2-y: 287;
            }
        }
    }
}
</style>